<template>
  <div class="app-main__inner studyCenter">
      <div class="row">
          <div class="col-md-12 col-sm-12 fullWidth bottomMargin">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-network icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Module Mock Test
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-container">
        <div class="row">
          <div class="col-lg-12 col-md-12 fullWidth">
            <b-tabs content-class="mt-3" justified lazy>
              <b-tab title="Speaking" active @click="selectTab('Speaking')">
                </b-tab>
              <b-tab title="Writing" @click="selectTab('Writing')">
              </b-tab>
              <b-tab title="Reading" @click="selectTab('Reading')"></b-tab>
              <b-tab title="Listening" @click="selectTab('Listening')"></b-tab>
            </b-tabs>
            <div class="col-12 collaps pl-0">
                    <div v-for="(item, index) in mockTestList" :key="index">
                      <!-- <div > -->
                        <b-card :title="'Practice '+selectedModule+' Test '+(index+1)" :sub-title="'PAID-'+ item.id">
                          <div class="col-12 text-right minWidth">
                      
                            <b-button v-if="(user_type=='premium' & item.user_id== user_id &item.user_mock_id!=null)" @click="getMockAnswers(item.user_mock_id,item.id)" class="mr-3" variant="success">Review Answers</b-button>
                            
                            <!-- <b-button v-if="item.user_id== user_id & item.user_mock_id!=null & item.mock_status=='Start'" @click="continuePratice(item.id, item.user_mock_id)" class="mr-3" variant="outline-warning">Continue Practice</b-button> -->
                            <b-button :disabled="user_type!='premium' | currentUser.is_admin!='T'" variant="outline-primary" @click="startModuleMockTest(item.id)">Start Practice</b-button>
                            <!-- <b-button v-else-if="user_type=='standard' & index<10" variant="outline-primary" @click="startMockTest(item.id)">Start Practice</b-button>
                            <b-button v-else-if="user_type=='Free' & item.mock_status_type=='Free'" variant="outline-primary" @click="startMockTest(item.id)">Start Practice</b-button> -->
                          </div>
                        </b-card>
                    </div>
                </div>
</div>
</div>
</div>
<b-modal ref="answersModal" id="answersModal" size="xl" scrollable hide-footer title="Module Mock Answers Review">
      <app-Collaps :type="'mockAnswer'" :mockId="selectedMockId" :userMockId="selectedUserMockId"/>
    </b-modal>
</div>

</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Collaps from '@/components/reuseable/collaps' 
export default {
  components:{
    'app-Collaps':Collaps
  },
  data(){
    return{
      selectedModule:'Speaking',
      mockTestList:null,
      user_id:0,
      user_type:'',
      currentUser:null,
      selectedMockId:0,
      selectedUserMockId:0,
    }
  },
  methods:{
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData',
            UserPersonalInfofetch:'auth/fetchUserPersonalInfo',
        }),
        ...mapGetters({
            GetQuestionPractice:'auth/getPracticeNumber',
            getUserPersonalinfo: 'auth/getPersonalInfo',
        }),
    selectTab(tab){
      this.selectedModule=tab;
      this.$ls.set('tabModule',tab)
      this.fetchMockTests();
    },
    fetchMockTests(){
            const url='overallPractice/getModuleMockTestList/id='+this.user_id
            var vm=this;
            vm.mockTestList=null;
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                console.log(response)
                if(response!=null)
                if(response.success==1){
                  //debugger
                    vm.mockTestList=response.data
                    //Sorting 
                    // vm.mockTestList=vm.mockTestList.sort(function(a, b) {
                    //       if (a.id !== b.id) {
                    //           return a.id - b.id
                    //       }
                    //       if (a.name === b.name) {
                    //         return 0;
                    //       }
                    //       return a.name > b.name ? 1 : -1;
                    //   });
                      // filtering
                     // vm.mockTestList=vm.mockTestList.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
                     let maxNumber= 28;
                     let minNumber=21
                     if(this.selectedModule=="Writing"){
                      minNumber=maxNumber;
                      maxNumber+=7;
                     }
                     else if(this.selectedModule=="Reading"){
                      minNumber=maxNumber+7;
                      maxNumber+=14;
                     }
                     else if(this.selectedModule=="Listening"){
                      minNumber=maxNumber+14;
                      maxNumber+=21;
                     }
                    
                      debugger;
                      vm.mockTestList=vm.mockTestList.sort(s=>s.id).slice(minNumber,maxNumber);
                      console.log(vm.mockTestList);
                    
                }
            })
    },
    startModuleMockTest(mockId){
    //console.log(mockId)
    if(this.user_type=='Free' | this.user_type=='standard'){
     return this.$confirm(
                              "To practice module wise mock questions you need to upgrade your plan.",
                              "Failed",
                              "question",
                                {
                                    confirmButtonText: "Buy Premium",
                                    cancelButtonText: "Cancel"
                                }
                            )
                              .then((r) => {
                                if(r){
                                  this.$parent.callMenuClick('settings')
                                  return console.log("Purchase selected.")
                                }
                                else{
                                 return
                                }
                              })
                              .catch(() => {
                                console.log("OK not selected.");
                                return;
                              });
    }
          this.$ls.set('mockTestId',mockId)
          // I send it as module name for module wise mock test.
          this.$ls.set('user_mock_id',this.selectedModule)
          this.$nextTick(function(){
            let routeData = this.$router.resolve({name: 'mockTest'}); 
            window.open(routeData.href, '_blank');
            })
        },
    getMockAnswers(user_mock_id,mockId){
          if(this.user_type=='Free' | this.user_type=='standard'){
          return this.$confirm(
                                    "To practice module wise mock questions you need to upgrade your plan.",
                                    "Failed",
                                    "question",
                                      {
                                          confirmButtonText: "Buy Premium",
                                          cancelButtonText: "Cancel"
                                      }
                                  )
                                    .then((r) => {
                                      if(r){
                                        this.$parent.callMenuClick('settings')
                                        return console.log("Purchase selected.")
                                      }
                                      else{
                                      return
                                      }
                                    })
                                    .catch(() => {
                                      console.log("OK not selected.");
                                      return;
                                    });
          }
          this.selectedMockId=mockId;
          console.log(this.selectedMockId);
          this.selectedUserMockId=user_mock_id
          console.log(this.selectedUserMockId);
          this.$refs['answersModal'].show()
        },
  },
  async beforeMount(){
    
    this.user_id=this.$ls.get('user').user_id
    this.currentUser=this.$ls.get('user')
    this.user_type=this.currentUser.user_type;
    this.$ls.set('tabModule','Speaking') //Default value.
    this.fetchMockTests();
  }
}
</script>