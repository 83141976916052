<template>
    <div class="app-main__inner">
        <div class="row">
            
            <div class="col-md-12">
                <div class="app-page-title dashboard-title">
                    <div class="page-title-wrapper">
                        <div class="page-title-heading">
                            <div class="page-title-icon">
                                <i class="pe-7s-science icon-gradient bg-happy-itmeo">
                                </i>
                            </div>
                            <div>
                                Why You Should Subscribe to the Premium Package of PTEClasses.Online Portal
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-8"> -->
            <div class="col-lg-12">
                <p>
                    PTEClasses.Online Portal is a leading platform for preparing for the PTE Academic Exam. Whether you're looking to study abroad or apply for a job, the PTE Exam is an important step towards achieving your goal. To help you get the best results, PTEClasses.Online Portal offers a premium package that provides you with access to everything you need to prepare for the exam with confidence.
                </p>
            </div>
            <div class="col-lg-12">
                <p>Here are some reasons why you should subscribe to the premium package of PTEClasses.Online Portal:</p>
                <ul>
                    <li>
                       <p> <strong>Unlimited Website Access: </strong>
                        The premium package gives you unlimited access to all the resources on the PTEClasses.Online Portal website. You'll have access to all the lessons, practice tests, and other materials you need to prepare for the PTE Exam.
                       </p>
                    </li>

                    <li>
                       <p> <strong>20 Mock Tests with Scoring: </strong>
                        The premium package includes 20 mock tests that you can take to practice for the PTE Exam. Each test will be scored, so you'll know exactly where you stand and what you need to work on.
                    </p>
                    </li>

                    <li>
                       <p> <strong>Access to Predicted Questions: </strong>
                        PTEClasses.Online Portal has a team of experts who analyze the latest trends and patterns in the PTE Exam. With the premium package, you'll have access to their predicted questions, so you can focus your study on the most important topics.
                       </p>
                    </li>

                    <li>
                       <p> <strong>Unlimited Writing and Speaking Assessment Scoring: </strong>
                        The premium package also includes unlimited writing and speaking assessment scoring. This means you can get as many writing and speaking assessments scored as you need, to help you improve your skills and get ready for the PTE Exam.
                       </p>
                    </li>
                </ul>
                <p>
                    With all these benefits, the premium package is an excellent investment in your future. For just 10.99 USD, you can get unlimited access to all the resources you need to prepare for the PTE Exam with confidence. Subscribe today and start your journey towards success!
                </p>
            </div>
        <!-- </div> -->
            <!-- <div class="col-lg-4">
                <img src="@/assets/images/pte-whypremium.png"/>
            </div> -->
        </div>
    </div>
    </template>
    
    <script>
    
    export default {
      data () {
        return {
        }
      }
    }
    </script>
<style scoped>
p{
    color: #000;
    padding:10px 50px;
}
ul{
    padding-left: 65px;
}
ul li p{
    padding-left: 0px;
}
</style>