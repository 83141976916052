<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-albums icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Plan Notifications</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of paid users plan
                </div>
                <div class="card-body">
            <div class="overflow-auto">
      <b-row>

      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="username">User Name</b-form-checkbox>
            <b-form-checkbox value="email_id">Email</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table striped hover :items="allNotifications" :per-page="perPage"
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                <template #cell(user_notification_id)="data">
                   <b-button variant="outline-info" :disabled="data.item.admin_notify=='T'" @click="responseToUser(data.item.user_notification_id)">
                     Noted
                   </b-button>
                  </template>
                  <template  #cell(admin_notify)="data">
                    <div class="text-center"><b-badge class="successBg" v-if="data.item.admin_notify=='T'" variant="success"> 1</b-badge>
                    <b-badge class="dangerBg" v-else variant="danger"> 0</b-badge>
                    </div>
                  </template>
                 <template #cell(username)="data">
                            <i>{{ data.item.username }}</i>
                          </template>
                          <template #cell(email_id)="data">
                            <i>{{ data.item.email_id }}</i>
                          </template>
                          <template #cell(pte_status)="data">
                            <i>{{ data.item.pte_status }}</i>
                          </template>
                          <template  #cell(pteExpiry)="data">
                            <i>{{changeDate(data.item.pteExpiry, "fullDate")}}</i>
                          </template> 
                          <template #cell(coaching)="data">
                            <i>{{ data.item.coaching }}</i>
                          </template>
                          <template  #cell(coachingExpiry)="data">
                            <i>{{changeDate(data.item.coachingExpiry, "fullDate")}}</i>
                          </template> 
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from "moment";
import { mapActions,mapGetters } from 'vuex'
export default {
    props:['usersPlansNotification'],
    data(){
        return{
        perPage: 10,
        sortDirection: 'asc',
        currentPage: 1,
        popupTitle:'Response to ',
        minDate:'',
        maxDate:'',
        totalRows: 1,
        filter: null,
        filterOn: [],
        adminResponse:'',
        responseTitle:'',
        sendResponseWait:true,
        fields: [
          {
            key: 'user_notification_id',
            label: '',
            sortable: false,
          },
          {
            key: 'admin_notify',
            label: 'Responed',
            sortable: false,
          },
          {
            key: 'username',
            label: 'User',
            sortable: true,
          },
          {
            key: 'email_id',
            label: 'Email',
            sortable: true,
          },
          {
            key: 'pte_status',
            label: 'PTE Classes',
            sortable: true,
          },
          {
            key: 'pteExpiry',
            label: 'PTE Expiry',
            sortable: true,
          },
          {
            key: 'coaching_status',
            label: 'Coaching',
            sortable: true,
          },
          {
            key: 'coachingExpiry',
            label: 'Coaching Expiry',
            sortable: true,
          }
        ],
        allNotifications:this.usersPlansNotification,
        notificationDetail:null
        }
    },
    computed: {
      rows() {
        if(this.allNotifications==null) {//console.log('returning 0')
        return  0}
        else{
          return this.allNotifications.length
        }
        
      }
    },
  watch: {
    adminResponse: function () {
      this.sendResponseWait=(this.adminResponse=='' | this.responseTitle=='')?true:false
    },
    responseTitle: function () {
      this.sendResponseWait=(this.adminResponse=='' | this.responseTitle=='')?true:false
    }
  },
    methods:{
      ...mapActions({
            saveData: 'speakmodule/saveAnswer',
        }),
        ...mapGetters({
            dataResponse: 'speakmodule/AnswerResponse',
        }),
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      resetModal(){

      },
      response(){
        var data={
          user_notification_id:this.notificationDetail.user_notification_id,
          admin_notify:'T',
          url:'users/updateUserNotificationAdminResponse'
        }
        this.saveData(data).then(()=>{
          const response=this.dataResponse()
          if(response.success==1){
            var index=this.allNotifications.findIndex((obj => obj.user_notification_id == this.notificationDetail.user_notification_id))
            this.allNotifications[index].admin_notify='T'
            //this.$alert('Your message is deliverd to respect user.')
          }

        })
        //console.log(data)
        
      },
      responseToUser(user_notification_id){
        //console.log(user_notification_id)
        
        this.notificationDetail=this.allNotifications.filter((item)=>{
          return item.user_notification_id==user_notification_id
        })[0]
        this.response()
      },
      minMaxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      //minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(minDate.getDate()+1)
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth() + 12)
      //maxDate.setDate(15)
        this.minDate=minDate
        this.maxDate=maxDate
    },
     changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-D");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
      // fetchUsersMessages(){
      // var url='users/getallUserMessages'
      //  this.fetchUsersMessages(url).then(()=>{
      //     const response=this.GetUsersMessages()
      //     this.allNotifications=response.data
      //     ////console.log(this.allNotifications)
      // });
      // }
    }
}
</script>
<style scoped>
.successBg, .dangerBg
{
      color: #32cd32!important;
    border-radius: 50%;
    /* width: 15px; */
    padding: 0px;
    height: 14px;
    min-width: 14px;
}
.dangerBg
{
  color: #d92550!important;
}
</style>